import { env } from 'next-runtime-env';

export const SETTINGS = {
  public: {
    tmsToken: env('NEXT_PUBLIC_TMS_TOKEN'),
    urlAccountSvc: env('NEXT_PUBLIC_URL_ACCOUNT_SVC'),
    urlContractWeb: env('NEXT_PUBLIC_URL_CONTRACT_WEB'),
    urlContractSvc: env('NEXT_PUBLIC_URL_CONTRACT_SVC'),
    urlPlanWeb: env('NEXT_PUBLIC_URL_PLAN_WEB'),
    urlClubePublicationWeb: env('NEXT_PUBLIC_URL_CLUBE_PUBLICATION_WEB'),
    urlClubeAdminWeb: env('NEXT_PUBLIC_URL_CLUBE_ADMIN_WEB'),
    urlClubePartnerWeb: env('NEXT_PUBLIC_URL_CLUBE_PARTNER_WEB'),
    urlMedicationsWeb: env('NEXT_PUBLIC_URL_MEDICATIONS_WEB'),
    urlPosthumousWeb: env('NEXT_PUBLIC_URL_POSTHUMOUS_WEB'),
    urlHealthWeb: env('NEXT_PUBLIC_URL_HEALTH_WEB'),
    urlAccountWeb: env('NEXT_PUBLIC_URL_ACCOUNT_WEB'),
    urlAuthWeb: env('NEXT_PUBLIC_URL_AUTH_WEB'),
    urlHubWeb: env('NEXT_PUBLIC_URL_HUB_WEB'),
    urlBillingWeb: env('NEXT_PUBLIC_URL_BILLING_WEB'),
    urlLinkWeb: env('NEXT_PUBLIC_URL_LINK_WEB'),
    urlOrderWeb: env('NEXT_PUBLIC_URL_ORDER_WEB'),
    urlSaleWeb: env('NEXT_PUBLIC_URL_SALE_WEB'),
    urlRoleWeb: env('NEXT_PUBLIC_URL_ROLE_WEB'),
    urlChatWeb: env('NEXT_PUBLIC_URL_CHAT_WEB'),
    urlRoleSvc: env('NEXT_PUBLIC_URL_ROLE_SVC'),
    urlTelevetWeb: env('NEXT_PUBLIC_URL_TELEVET_WEB'),
    basePath: env('NEXT_PUBLIC_BASE_PATH'),
  },
  server: {
    basePath: env('BASE_PATH'),
  },
};
